import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/common/Layout.jsx';
import CareTreatmentBanner from '../components/care-treatment/CareTreatmentBanner.jsx';
import { Helmet } from 'react-helmet';

class CareTreatment extends React.PureComponent {
    render(){
        return (
            <Layout>
                <Helmet>
                    <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta name="description" content="Read now for heart information in dogs." />
                    <meta name="robots" content="index, follow" />
                    <meta property="og:title" content="Care and treatment - Boehringer Ingelheim" />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content="Read now for heart information in dogs." />
                    <meta property="og:locale" content="en_AU" />

                    <title>Care and treatment - Boehringer Ingelheim 2019</title>
                </Helmet>
                <CareTreatmentBanner/>
                <div className="CareTreatmentMainContainer">
                    <div className="ContentContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionText">Although your dog may have been diagnosed with a heart disease such as mitral valve disease or dilated cardiomyopathy, there are many things you can do to help your dog with a heart condition.</p>
                                <p className="SectionText">Here you will find some general tips for the care of your dog with a heart condition. However, these tips aren't a substitute to the personalised care your dog receives from your vet – so always seek professional advice.</p>
                                <p className="SectionText">It is important to remember that to date, there is no cure for mitral valve disease or cardiomyopathy in dogs. However, there are treatments that not only greatly improve your dog's quality of life, but can also extend your dog's life span.</p>
                                <p className="SectionTitle">Your vet may prescribe medications to help manage your dog's heart condition. These medications may include:</p>
                                <ul className="SectionList">
                                    <li className="SectionListElement">&bull; diuretics that remove excess fluid from the lungs or abdomen</li>
                                    <li className="SectionListElement">&bull; medicines that allow the heart to work more efficiently which should help your dog to live longer</li>
                                    <li className="SectionListElement">&bull; medicines that 'open up' constricted blood vessels, so reducing the work your dog's weakened heart has to do</li>
                                    <li className="SectionListElement">&bull; medicines that improve the strength, the rate or the rhythm of your dog's heart beat</li>
                                </ul>
                                <p className="SectionText">Your vet may prescribe more than one medication to help treat different aspects of heart failure, and may also need to make adjustments to the treatments (because heart failure can progress over time).</p>
                                <p className="SectionText">It is important you follow your vet's recommendations in managing your dog's condition, including treatment. Ask your vet if you are unsure of anything related to your dog's treatment regime. Understanding the purpose and possible side effects helps you help your dog.</p>
                                <p className="SectionTitle">Lifestyle</p>
                                <p className="SectionText">As well as providing medication, lifestyle changes are important.</p>
                                <p className="SectionTitle">Exercise</p>
                                <p className="SectionText">Regular mild to moderate exercise is thought to be beneficial for dogs that have only mild signs or symptoms. However, if your dog seems tired, stop exercising them.<br />It's very important to seek your vet's advice about whether exercise is suitable for your dog and to what level. Some dogs may need complete rest. Contact your vet if your dog collapses or seems very weak during activity.</p>
                                <p className="SectionTitle">Diet</p>
                                <p className="SectionText">Your vet may recommend reducing the salt content in your dog's diet. Your vet will be able to advise you on the right type of pet food for your dog that is nutritionally balanced and lower in salt.
                                Treats and 'people food' like chips, cheese and processed meat are all high in salt and aren't suitable for a dog with heart failure.</p>
                                <p className="SectionTitle">Observation</p>
                                <p className="SectionText">Keep an eye on your dog as they go about their daily activities – pay attention to your dog's appetite, level of movement, and attitude. Counting breaths per minute (or respiratory rate) can help you monitor your dog's heart function. <strong>To do this, simply count the number of times your dog breathes (inhale + exhale = one breath) in one minute during relaxed sleep.</strong></p>
                                <p className="SectionText">If your dog's resting respiratory rate is consistently more than 30 breaths per minute, please let your vet know as this may be an indicator of disease progression. With you and your vet working together, your dog will get the best possible care.</p>
                                <p className="SectionText"><Link to="/measure-health">Check out our Respiratory Rate Tracker App</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default CareTreatment;
