import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './CareTreatmentBanner.module.css';

class CareTreatmentBannerImage extends React.PureComponent {
    render(){
        return (
            <Img
                fluid={this.props.careTreatmentBannerImage.childImageSharp.fluid}
            />
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                careTreatmentBannerImage: file(relativePath: { eq: "careTreatment/care-and-treatment-banner.png" }){
                    childImageSharp {
                        fluid(maxWidth: 1440){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}

        render={data => {
            // let isIE = /*@cc_on!@*/false || !!document.documentMode;
            let isIE = false;

            if( isIE ){
                return (
                    <div className={style.HealthyHeartsBanner}>
                        <div className={style.HealthyHeartsImageContainer}>
                            <CareTreatmentBannerImage careTreatmentBannerImage={data.careTreatmentBannerImage} className={style.BannerImage} />
                        </div>
                        <div className={style.HealthyHeartsTitleContainerIE}>
                            <div className={style.CTTitleTable}>
                                <div className={style.CTTitleTableCell}>
                                    <p className={style.HealthyHeartsTitle}>Care and treatment</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.HealthyHeartsBreadcrumbsContainer}>
                            <Link to="/" className={style.HealthyHeartsBreadcrumb}>Home</Link>&nbsp;>&nbsp;
                            Care and treatment
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className={style.HealthyHeartsBanner}>
                        <div className={style.HealthyHeartsImageContainer}>
                            <CareTreatmentBannerImage careTreatmentBannerImage={data.careTreatmentBannerImage} className={style.BannerImage} />
                        </div>
                        <div className={style.HealthyHeartsTitleContainer}>
                            <p className={style.HealthyHeartsTitle}>Care and treatment</p>
                        </div>
                        <div className={style.HealthyHeartsBreadcrumbsContainer}>
                            <Link to="/" className={style.HealthyHeartsBreadcrumb}>Home</Link>&nbsp;>&nbsp;
                            Care and treatment
                        </div>
                    </div>
                )
            }
        }}
    />
)
